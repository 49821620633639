import React from "react";
import UserContext from "../contexts/UserContext";
import TriageOne from "../components/TriageOne";





export default function Triage () {

    const {token} = React.useContext(UserContext)

    const [triageList, setTriageList] = React.useState([])
    const [scanned, setScanned] = React.useState(0)

    

    function scan() {
        setScanned(1)
        fetch('https://triage.arkanic.com/', {
            method: 'POST',
            headers: {
                token: token
            },
            body: JSON.stringify({function: 'scan'})
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            res.json().then(v => {
                if ("Items" in v) {
                    setTriageList(v['Items'])
                }
            })
            setScanned(2)
        }).catch(e => {
            console.error('FETCH CRASHED')
            console.error(e)
            setScanned(-1)
        })
    }

    React.useEffect(()=> {console.log(triageList)}, [triageList])

    switch (scanned) {
        case 0: return(
            <div className="centralise centralRow">
                <div className="editButton">
                    <button onClick={e=>scan()}>Scan</button>
                </div>
            </div>)
        case 1: return(<div className="centralise text">Scanning</div>)
        case 2: 
            if (triageList && triageList.length) {return(<div className="centralise text overflow-y-scroll">
                {triageList.map(e => {return(<TriageOne operator={e}/>)})}
            </div>)} else {return(
            <div className="centralise text">List Empty</div>
            )}
        default: return(<div className="centralise text">Error</div>)
    }
    
}

