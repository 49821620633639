import React from "react";
import UserContext from "../contexts/UserContext";
import MonitorOne from "../components/MonitorOne";





export default function Monitor () {

    const {token} = React.useContext(UserContext)

    const [keyList, setKeyList] = React.useState([])
    const [scanned, setScanned] = React.useState(0)

    

    function scan() {
        setScanned(1)
        fetch('https://s3.arkanic.com/', {
            method: 'POST',
            headers: {
                token: token
            },
            body: JSON.stringify({function: 'scan', bucket: 'arkanic-data-monitor'})
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            res.json().then(v => {
                setKeyList(v)
            })
            setScanned(2)
        }).catch(e => {
            console.error('FETCH CRASHED')
            console.error(e)
            setScanned(-1)
        })
    }

    function generate(func:string) {
        fetch('https://s3.arkanic.com/', {
            method: 'GET',
            headers: {
                token: token,
                functions: func
            }
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            res.json().then(v => {
                scan()
            })
        }).catch(e => {
            console.error('FETCH CRASHED')
            console.error(e)
        })
    }

    switch (scanned) {
        case 0: return(
            <div className="centralise centralRow">
                <div className="editButton">
                    <button onClick={e=>scan()}>Scan</button>
                </div>
            </div>)
        case 1: return(<div className="centralise text">Scanning</div>)
        case 2: 
            if (keyList && keyList.length) {return(
            <div className="centralise text overflow-y-scroll">
                {keyList.map(e => {return(<MonitorOne s3key={e} key={e}/>)})}
                
                <div className="centralise flex-row min-h-[20%]">
                    <div className="editButton">
                        <button onClick={e=> {
                            if (window.confirm('Generate current stock data')) {
                                generate('stock')
                            }
                        }}>Generate current stock data</button>
                    </div>
                </div>

                <div className="centralise flex-row min-h-[20%]">
                    <div className="editButton">
                        <button onClick={e=> {
                            if (window.confirm('Generate current harvest data')) {
                                generate('harvest')
                            }
                        }}>Generate current harvest data</button>
                    </div>
                </div>

                <div className="centralise flex-row min-h-[20%]">
                    <div className="editButton">
                        <button onClick={e=> {
                            if (window.confirm('Generate current discard data')) {
                                generate('discard')
                            }
                        }}>Generate current discard data</button>
                    </div>
                </div>

                <div className="centralise flex-row min-h-[20%]">
                    <div className="editButton">
                        <button onClick={e=> {
                            if (window.confirm('Generate current seed data')) {
                                generate('seeds')
                            }
                        }}>Generate current seed data</button>
                    </div>
                </div>

            </div>)} else {return(
            <div className="centralise text">No Data</div>
            )}
        default: return(<div className="centralise text">Error</div>)
    }
}

