import React from "react";
import UserContext from "../contexts/UserContext";



export default function MonitorOne ({s3key}:any) {

    const {token} = React.useContext(UserContext)

    function get() {

        fetch('https://s3.arkanic.com/', {
            method: 'POST',
            headers: {
                token: token
            },
            body: JSON.stringify({
                function: 'get',
                bucket: 'arkanic-data-monitor',
                key: s3key
            })
        }).then(res => {
            if (!res.ok) {
                throw {
                    error: 'Request error',
                    code: res.status,
                    response: res
                }
            }
            res.json().then(c=>{window.location.assign(c)})
        }).catch(e => {
            console.error('FETCH FAILED')
            console.error(e)
        })
    }

    return(
        <div className="centralise flex-row min-h-[15%]">
            <div> {s3key} </div>
            <div className="editButton">
                <button onClick={e=> {
                    if (window.confirm(`Download ${s3key}`)) {
                        get()
                    }
                }}>Download</button>
            </div>
        </div>
    )
}

