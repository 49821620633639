import React from "react";
import User from "../pages/User";
import Triage from "../pages/Triage";
import Monitor from "../pages/Monitor";





const Tabs = [
    {
        'route':'user',
        'display':'User',
        'permissions':'arkanic-verified',
        'element': <User />
    },
    {
        'route':'triage',
        'display':'Operators Triage',
        'permissions':'arkanic-admin',
        'element': <Triage />
    },
    {
        'route':'monitor',
        'display':'Data Monitor',
        'permissions':'data-monitor',
        'element': <Monitor />
    },
    // {
    //     'route':'p1',
    //     'display':'Page One',
    //     'permissions':'hardware-admin',
    //     'element': <>Hi from Page 1</>
    // },
]


export default Tabs;

